<template>
  <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.76205 1.32167C7.70085 1.26025 7.6281 1.21153 7.548 1.17833C7.46789 1.14514 7.38201 1.12811 7.29529 1.12824H3.99431C3.90759 1.12811 3.82171 1.14514 3.74161 1.17833C3.6615 1.21153 3.58875 1.26025 3.52755 1.32167L1.54695 3.30227C1.48553 3.36347 1.43681 3.43622 1.40361 3.51633C1.37042 3.59643 1.35339 3.68231 1.35352 3.76903V7.07001C1.35352 7.24563 1.42284 7.41332 1.54695 7.53677L8.14893 14.1388C8.2101 14.2003 8.28283 14.2491 8.36293 14.2824C8.44304 14.3157 8.52893 14.3329 8.61569 14.3329C8.70245 14.3329 8.78834 14.3157 8.86845 14.2824C8.94855 14.2491 9.02128 14.2003 9.08245 14.1388L14.364 8.85717C14.4254 8.79591 14.4741 8.72315 14.5073 8.64306C14.5405 8.56297 14.5576 8.47711 14.5576 8.39041C14.5576 8.3037 14.5405 8.21785 14.5073 8.13776C14.4741 8.05766 14.4254 7.98491 14.364 7.92365L7.76205 1.32167ZM5.54775 6.40982C5.40275 6.41343 5.25849 6.388 5.12347 6.33502C4.98844 6.28204 4.86538 6.20258 4.76153 6.10132C4.65768 6.00006 4.57514 5.87905 4.51877 5.7454C4.46239 5.61176 4.43333 5.46819 4.43329 5.32314C4.43324 5.17809 4.46222 5.0345 4.51851 4.90082C4.5748 4.76715 4.65727 4.64608 4.76106 4.54476C4.86485 4.44343 4.98786 4.3639 5.12285 4.31084C5.25784 4.25777 5.40209 4.23226 5.54709 4.23579C5.83075 4.24269 6.10047 4.3602 6.29869 4.56323C6.4969 4.76627 6.60789 5.03874 6.60798 5.32248C6.60806 5.60622 6.49723 5.87876 6.29915 6.08191C6.10106 6.28506 5.83141 6.40274 5.54775 6.40982Z" fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconTag'
}
</script>
